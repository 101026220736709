import React from "react"
import Layout from "../components/Layout/Layout"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import HubSpotForm from "../components/HubSpotForm"
import HeaderImage from "../assets/img/new/tlo-serch-01.webp"
import { devMode } from "../components/Variables"

import "../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

i18n.changeLanguage("de")

const Kontakt = () => {
  const { t } = useTranslation()

  return (
    <Layout
      seo={{
        title: "Kostenloser Kostenvoranschlag für die Optimierung und Positionierung der Webseite • WeNet.pl",
        description:
          "Nutzen Sie den kostenlosen Kostenvoranschlag der Optimierung und Positionierung Ihrer Webseite • Füllen Sie das Formular aus und sorgen Sie für eine hohe Positionierung Ihrer Webseite in Google",
        hreflangs: {
          pl: "kontakt/",
          en: "en/contact/",
          de: "de/kontakt/",
        },
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Heim",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/de/kontakt/",
                name: t("seo-title-contact"),
              },
            },
          ],
        },
      }}
    >
      <div className="hero-header-page" style={{ backgroundImage: `url("${HeaderImage}")` }}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              { url: t("link-url-contact"), text: t("link-text-contact") },
            ]}
          />
        </div>
        <div className="container">
          <h1 className="title text-center">
            Kostenloser Kostenvoranschlag für die Optimierung und Positionierung der Webseite
          </h1>
        </div>
      </div>

      <section className="standard-section contact-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-md-10 offset-md-1 offset-lg-0 col-12">
              <p className="text-blue font-18" style={{ marginTop: "30px" }}>
                Möchten Sie einen kostenlosen Kostenvoranschlag erhalten? Füllen Sie das Formular aus und sorgen Sie für
                eine hohe Position Ihrer Webseite in Google. Wir setzen uns mit Ihnen in Verbindung und informieren Sie
                über die Einzelheiten des Angebots - völlig kostenlos.
                <br />
                <br />
              </p>
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-10 offset-md-1 offset-lg-0">
              <h2>Anfrageformular</h2>
              <HubSpotForm
                formId={
                  devMode === "true" ? "b98ffe82-3d3e-43c1-a157-76c5b55f85ee" : "2b45a738-86a3-47d4-a8f6-c0637a9e4e26"
                }
                formType="contact"
              />
            </div>
            <div className="col-xl-4 offset-xl-1 col-lg-6 col-md-10 offset-md-1 offset-lg-0">
              <p className="text-blue font-18">
                Hinterlassen Sie Ihre Daten und Sie erhalten:
                <br />
                <br />
              </p>
              <ul>
                <li>
                  einen genauen Kostenvoranschlag für Positionierungs- und Optimierungsdienste - Sie werden wissen, in
                  was Sie Ihr Geld investieren - bei uns gibt es keine versteckten Kosten,
                </li>
                <li>
                  ein detailliertes Angebots - Sie erfahren, was genau das von unserem Team erstellte Angebot umfasst -
                  wir sagen Ihnen direkt, was wir Ihrer Firma anbieten können,
                </li>
                <li>
                  Zugang zu einen Spezialisten - Sie erhalten Kontakt zu empfohlenen SEO-Spezialisten - wir teilen gerne
                  unser Wissen mit unseren Kunden und beraten sie über Lösungen, die auf ihre Bedürfnisse zugeschnitten
                  sind,
                </li>
                <li>
                  Wettbewerbsvorteil - Sie erfahren, was Sie verbessern können, um Ihre Webseite besser zu gestalten als
                  die Konkurrenz - wir sorgen für eine bessere Sichtbarkeit Ihrer Webseite in der Suchmaschine.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Kontakt
